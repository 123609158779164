<template>
  <div class="myHeaderTow">
    <div class="w1400">
      <div
        class="logo flc"
        v-show="showLogo"
        @click="skptIndex"
        title="点击跳转到门户"
      >
        <template v-if="showSerachValue">
          <img class="logo-img2" :src="logoImgUrl" alt="" />
        </template>
        <template v-else>
          <img class="logo-img1" :src="logoImgUrl" alt="" />
        </template>
      </div>
      <div class="mapdata" v-if='showMapValue'>
        <img :src="require('@/assets/images/mapdata.png')" class='mapdata-img' alt="">
        <el-cascader
          ref="mapCascader"
          :options="mapOptions"
          :props="{ value: 'code', label: 'name', checkStrictly: true }"
          placeholder="请选择地区"
          v-model="mapValue"
          @change="getmapdataValue"
          size="small"
        ></el-cascader>
      </div>
      <div class="userCont">
        <div class="flc" v-if="token">
          <div class="userName flc ml20">
            <img class="mr5" :src="avatar" alt="" />
            <el-button @click="spktUser" type="text" size="small">{{
              userName
            }}</el-button>
          </div>
          <el-button @click="lsetPwd" class="apply ml20" type="text"
            >修改密码</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button @click="loginOut" class="apply" type="text"
            >退出登录</el-button
          >
        </div>
        <div class="flc pr20" v-else>
          <template v-if="!showSerachValue">
            <el-button @click="openLoginModal" class="apply" type="text"
            >请登录</el-button
          >
          <el-button @click="openRegisterModal" class="apply" type="text"
            >注册</el-button
          >
          </template>
          <el-button v-else @click="openLogin" type="text">登录/注册</el-button>
          <!-- <el-button @click="openRegisterModal" class="apply" type="text"></el-button> -->
        </div>
      </div>
      <div class="serach-box flc mr20" v-if="showSerachValue">
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          size="small"
          @keyup.enter.native="toSerach"
          v-model="serachValue"
        ></el-input>
      </div>
    </div>
    <!-- 登录 -->
    <MyModal
      :show="showOpenLogin"
      modalTitle="登录"
      modalWidth="720px"
      class="logoModal"
      @setFalse="setVariableFalse"
    >
      <el-card slot="contenttTow" class="box-card">
        <div class="close-i" @click="setVariableFalse">
          <i class="el-icon-close"></i>
        </div>
        <div class="tac login-img">
          <img :src="loginlogo" alt="" />
        </div>
        <div class="tags">
          <div class="tagsBox">
            <div
              @click="checkItem(1)"
              :class="checkTag == 1 ? 'tagsItem active' : 'tagsItem'"
            >
              账号登录
            </div>
            <div
              @click="checkItem(2)"
              :class="checkTag == 2 ? 'tagsItem active' : 'tagsItem'"
            >
              验证码登录
            </div>
          </div>
        </div>
        <div style="height: 30px; width: 100%"></div>
        <el-form
          class="form-modal-box form-modal-login-box"
          :model="loginObj"
          :rules="rules"
          ref="ruleFormLogin"
          label-width="80px"
          label-position="left"
        >
          <template v-if="checkTag == 1">
            <el-form-item label="账号：" prop="userName">
              <el-input
                v-model="loginObj.userName"
                class="form-item"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码：" prop="userPwd">
              <el-input
                v-model="loginObj.userPwd"
                class="form-item"
                show-password
                placeholder="请输入"
                @keyup.enter.native="toLogin"
              ></el-input>
            </el-form-item>
            <div class="flx tal xieyi">
              <el-checkbox v-model="registrationProtocol"></el-checkbox>
              <el-button @click="setOpenZhucexieyi" type="text"
                >《易招考（江西）咨询服务有限公司用户注册协议》</el-button
              >
              <el-button type="text" style="color: #222">和</el-button>
              <el-button @click="setOpenGerenxieyi" type="text"
                >《易招考个人信息保护协议》</el-button
              >
            </div>
            <el-button @click="toLogin" class="loginBtn" type="primary"
              >登录</el-button
            >
          </template>
          <template v-else>
            <el-form-item label="手机号：" prop="phone">
              <el-input
                v-model.number="loginObj.phone"
                class="form-item"
                placeholder="请输入"
                maxlength="11"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码：" prop="code">
              <el-row>
                <el-col :span="15">
                  <el-input
                    class="form-item"
                    v-model="loginObj.code"
                    placeholder="请输入"
                    @keyup.enter.native="toDxLogin"
                  ></el-input>
                </el-col>
                <el-col :span="8" :offset="1"
                  ><el-button
                    type="primary"
                    class="code-item"
                    :disabled="loginObj.checkCodeTitle != '获取验证码'"
                    @click="sendCode(1)"
                  >
                    {{ loginObj.checkCodeTitle }}
                  </el-button></el-col
                >
              </el-row>
            </el-form-item>
            <div class="tal">
              <el-checkbox v-model="registrationProtocol"></el-checkbox>
              <el-button @click="setOpenZhucexieyi" type="text"
                >《易招考（江西）咨询服务有限公司用户注册协议》</el-button
              >
              <el-button type="text" style="color: #222">和</el-button>
              <el-button @click="setOpenGerenxieyi" type="text"
                >《易招考个人信息保护协议》</el-button
              >
            </div>
            <el-button @click="toDxLogin" class="loginBtn" type="primary"
              >登录</el-button
            >
          </template>
        </el-form>
        <div class="toRegisterBtn tal mt10">
          <el-button @click="toOpenRegister" class="loginBtn" type="text"
            >没有账号 点击注册！</el-button
          >
        </div>
        <div style="height: 20px; width: 100%"></div>
      </el-card>
    </MyModal>
    <!-- 注册 -->
    <MyModal
      :show="modalObj.openRegister"
      modalTitle="注册个人用户"
      modalWidth="800px"
      @setFalse="setVariableFalse"
    >
      <div slot="content" class="tac">
        <el-form
          class="mt20 form-modal-box"
          :model="loginObj"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          label-position="left"
        >
          <el-form-item label="手机号：" prop="phone">
            <el-input
              v-model.number="loginObj.phone"
              class="form-item"
              placeholder="请输入"
              maxlength="11"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码：" prop="code">
            <el-row>
              <el-col :span="18">
                <el-input
                  v-model="loginObj.code"
                  placeholder="请输入"
                ></el-input>
              </el-col>
              <el-col :span="6"
                ><el-button
                  type="primary"
                  class="code-item"
                  :disabled="loginObj.checkCodeTitle != '获取验证码'"
                  @click="sendCode(2)"
                >
                  {{ loginObj.checkCodeTitle }}
                </el-button></el-col
              >
            </el-row>
          </el-form-item>
          <el-form-item label="密码：" prop="userPwd">
            <el-input
              v-model="loginObj.userPwd"
              class="form-item"
              show-password
              placeholder="请输入"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="newUserPwd">
            <el-input
              v-model="loginObj.newUserPwd"
              class="form-item"
              show-password
              placeholder="请输入"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <div class="fz12 mb20">
            提示：仅支持大小写字母，数字，特殊字符；密码长度不少于8位，需要包含字母和数字
            最大长度不超过20位
          </div>
          <div class="tal">
            <el-checkbox v-model="registrationProtocol"></el-checkbox>
            <el-button @click="setOpenZhucexieyi" type="text"
              >《易招考（江西）咨询服务有限公司用户注册协议》</el-button
            >
            <el-button type="text" style="color: #222">和</el-button>
            <el-button @click="setOpenGerenxieyi" type="text"
              >《易招考个人信息保护协议》</el-button
            >
          </div>
          <el-button @click="toRegister" class="loginBtn" type="primary"
            >注册</el-button
          >
        </el-form>
      </div>
    </MyModal>
    <!-- 修改密码 -->
    <MyModal
      :show="modalObj.openPassword"
      modalTitle="修改密码"
      @setServeBtn="passwordServe"
      modalWidth="800px"
      @setFalse="setVariableFalse"
      :showFooter="true"
    >
      <div slot="content" class="tac">
        <el-form
          class="mt20 form-modal-box"
          :model="loginObj"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          label-position="left"
        >
          <el-form-item label="密码：" prop="userPwd">
            <el-input
              v-model="loginObj.userPwd"
              class="form-item"
              show-password
              placeholder="请输入"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码：" prop="newUserPwd">
            <el-input
              v-model="loginObj.newUserPwd"
              class="form-item"
              show-password
              placeholder="请输入"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <div class="fz12 mb20">
            提示：仅支持大小写字母，数字，特殊字符；密码长度不少于8位，需要包含字母和数字
            最大长度不超过20位
          </div>
        </el-form>
      </div>
    </MyModal>
    <!-- 注册协议 -->
    <ZhuceXieyi
      :show="modalObj.openZhucexieyi"
      @setFalse="setFalse"
    ></ZhuceXieyi>
    <!-- 易招考个人信息保护协议 -->
    <GerenxinxiBaohuXieyi
      :show="modalObj.openGerenxieyi"
      @setFalse="setFalse"
    ></GerenxinxiBaohuXieyi>
  </div>
</template>

<script>
// import AMap from 'AMap';
import loginlogo from "@/assets/images/loginlogo.png";
import {
  getToken,
  setToken,
  getUserName,
  setUserName,
  removeToken,
  removeUserName,
  setAvatar,
  getAvatar,
} from "@/utils/auth";
import {
  smsLogin,
  memberPwdLogin,
  sendCode,
  memberRegister,
  updatePwd,
  proCityList,
} from "@/api/pc";
import { uuid, validPhone, validPwd } from "@/utils/commom";
export default {
  name: "myHeaderTow",
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.loginObj.userPwd !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    return {
      logo1: '',
      loginlogo: loginlogo,
      userName: getUserName(),
      token: getToken(),
      avatar: getAvatar(),
      showUser: false,
      modalObj: {
        openRegister: false,
        openPassword: false,
        // 注册协议弹窗
        openZhucexieyi: false,
        // 个人信息协议弹窗
        openGerenxieyi: false,
      },
      checkTag: 1,
      loginObj: {
        userName: null,
        userPwd: null,
        newUserPwd: null,
        phone: "",
        checkCodeTitle: "获取验证码",
        code: null,
        uuid: null,
      },
      rules: {
        userName: [{ required: true, message: "请输入账号", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: validPhone, trigger: "blur" },
        ],
        userPwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: validPwd, trigger: "blur" },
        ],
        newUserPwd: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          {
            required: true,
            validator: equalToPassword,
            message: "密码与确认密码不一致",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      itemrFalg: null,
      // 注册协议多选
      registrationProtocol: false,
      // 搜索條件
      serachValue: null,
      // 默认展示logo
      showLogo: true,
      // 全国的省市区县
      mapOptions: [],
      // 地址的默认值
      mapValue:[],
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        if (newVal.path.indexOf("/personalCenter") > -1) {
          this.showLogo = false;
        } else {
          this.showLogo = true;
        }
        // this.setShowheader(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    if (localStorage.mapCityList) {
      this.mapOptions = JSON.parse(localStorage.mapCityList);
    } else {
      proCityList().then((res) => {
        localStorage.mapCityList = JSON.stringify(res.data);
        this.mapOptions = res.data;
      });
    }
    this.getMapdata();
  },
  computed: {
    showOpenLogin() {
      return this.$store.state.showLogin;
    },
    showMapValue() {
      return this.$store.state.showMapValue;
    },
    logoImgUrl() {
      return this.$store.state.logoImgUrl;
    },
    showSerachValue() {
      return this.$store.state.showSerachValue;
    },
  },
  methods: {
    setLinkIcon(dataUrl){
        let href = dataUrl;
        // 创建新的 link 标签
        var newLink = document.createElement("link");
        newLink.rel = "icon";
        newLink.type = "image/x-icon";
        newLink.href = href;

        // 获取并移除原有的 favicon link（如果有多个，可能需要更精确的选择器）
        var oldLinks = document.querySelectorAll('link[rel="icon"]');
        for (var i = 0; i < oldLinks.length; i++) {
          oldLinks[i].parentNode.removeChild(oldLinks[i]);
        }
        // 将新创建的 link 插入到 head 中
        document.head.appendChild(newLink);
    },
    getmapdataValue(val){
      sessionStorage.province = val[0]?val[0]:'1';
      sessionStorage.city = val[1]?val[1]:null;
      this.$refs.mapCascader.dropDownVisible = false;
      this.mapValue = val.length == 0 ?  ['1'] : val
      this.setMapdataValue()
    },
    getMapdata() {
        sessionStorage.province = '1';
        this.mapValue = ['1']
        sessionStorage.city = null;
        this.setMapdataValue()
    },
    setMapdataValue(){
      this.$store.dispatch("SET_PROVINCE" ,sessionStorage.province);
      this.$store.dispatch("SET_CITY" ,sessionStorage.city);
    },
    // 跳转到搜索列表
    toSerach() {
      window.location.href = `#/serachList?title=${this.serachValue}`;
      this.serachValue = "";
    },
    // 跳转到门户页面
    skptIndex() {
      window.location.href = `#/menhu`;
    },
    // 打开注册协议
    setOpenZhucexieyi() {
      this.modalObj.openZhucexieyi = true;
    },
    // 关闭注册协议
    setFalse() {
      this.modalObj.openZhucexieyi = false;
      this.modalObj.openGerenxieyi = false;
    },
    // 打开注册协议
    setOpenGerenxieyi() {
      this.modalObj.openGerenxieyi = true;
    },

    // 关闭登录弹窗
    setVariableFalse() {
      this.$store.dispatch("SET_SHOWLOGIN", false);
      this.modalObj.openRegister = false;
      this.modalObj.openPassword = false;
      this.loginObj.newUserPwd = "";
      this.loginObj.userPwd = "";
      this.loginObj.phone = "";
      this.loginObj.code = "";
      this.loginObj.uuid = "";
      this.loginObj.userName = "";
      this.checkTag = 1;
      this.$refs["ruleFormLogin"]
        ? this.$refs["ruleFormLogin"].resetFields()
        : null;
    },
    // 切換登录方式
    checkItem(val) {
      this.$refs["ruleFormLogin"]
        ? this.$refs["ruleFormLogin"].resetFields()
        : null;
      this.checkTag = val;
      clearInterval(this.itemrFalg);
      this.loginObj.checkCodeTitle = "获取验证码";
    },
    // 打开登录弹窗
    openLoginModal() {
      //  if(this.showMapValue){
        //   localStorage.toUrl = this.$route.path
        //   this.$router.push({path:'/login'})
        //  }else{
        this.$refs["ruleFormLogin"]
          ? this.$refs["ruleFormLogin"].resetFields()
          : null;
        this.$store.dispatch("SET_SHOWLOGIN", true);
        // }
    },
    // 跳转到登录页面
    openLogin(){
      localStorage.toUrl = this.$route.path
      this.$router.push({path:'/login'})
    },
    // 打开注册弹窗
    openRegisterModal() {
      this.$refs["ruleForm"] ? this.$refs["ruleForm"].resetFields() : null;
      this.modalObj.openRegister = true;
    },
    // 发送验证码
    sendCode(val) {
      if (!this.loginObj.phone) {
        return this.$message.error("请填写手机号后操作！");
      }
      this.loginObj.uuid = uuid();
      sendCode({
        dxType: val,
        phone: this.loginObj.phone,
        uuid: this.loginObj.uuid,
        position: val,
      }).then((res) => {
        if (res.code == 200) {
          this.loginObj.checkCodeTitle = "60S";
          let num = 60;
          this.itemrFalg = setInterval(() => {
            if (num == 0) {
              clearInterval(this.itemrFalg);
              this.loginObj.checkCodeTitle = "获取验证码";
            } else {
              num--;
              this.loginObj.checkCodeTitle = num + "S";
            }
          }, 1000);
        }
      });
    },
    // 点击登录
    toLogin() {
      if (!this.registrationProtocol) {
        return this.$message.error("请阅读并勾选协议后登录");
      }
      this.$refs["ruleFormLogin"].validate((valid) => {
        if (valid) {
          let params = {
            phone: this.loginObj.userName,
            password: this.loginObj.userPwd,
          };
          memberPwdLogin(params).then((res) => {
            localStorage.userInfo = JSON.stringify({
              avatar: res.avatar,
              phone: res.phone,
              username: res.username,
            });
            setToken(res.token);
            setUserName(res.username);
            setAvatar(res.avatar);
            setTimeout(() => {
              this.userName = getUserName();
              this.token = getToken();
              this.avatar = getAvatar();
            }, 200);
            this.setVariableFalse();
            location.reload();
          });
        } else {
          return false;
        }
      });
    },
    // 短信登陆
    toDxLogin() {
      if (!this.registrationProtocol) {
        return this.$message.error("请阅读并勾选协议后登录");
      }
      this.$refs["ruleFormLogin"].validate((valid) => {
        if (valid) {
          let params = {
            phone: this.loginObj.phone,
            uuid: this.loginObj.uuid,
            code: this.loginObj.code,
          };
          smsLogin(params).then((res) => {
            localStorage.userInfo = JSON.stringify({
              avatar: res.avatar,
              phone: res.phone,
              username: res.username,
            });
            setToken(res.token);
            setUserName(res.username);
            setAvatar(res.avatar);
            setTimeout(() => {
              this.userName = getUserName();
              this.token = getToken();
              this.avatar = getAvatar();
            }, 200);
            this.setVariableFalse();
            location.reload();
          });
        } else {
          return false;
        }
      });
    },
    // 打开注册弹窗
    toOpenRegister() {
      this.$store.dispatch("SET_SHOWLOGIN", false);
      setTimeout(() => {
        this.modalObj.openRegister = true;
      }, 200);
    },
    // 注册
    toRegister() {
      if (!this.registrationProtocol) {
        return this.$message.error("请阅读并勾选协议后注册！");
      }
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let params = {
            phone: this.loginObj.phone,
            uuid: this.loginObj.uuid,
            code: this.loginObj.code,
            password: this.loginObj.userPwd,
          };
          memberRegister(params).then((res) => {
            this.$message.success("注册成功，请登录");
            localStorage.userInfo = JSON.stringify({
              avatar: res.avatar,
              phone: res.phone,
              username: res.username,
            });
            clearInterval(this.itemrFalg);
            setToken(res.token);
            setUserName(res.username);
            setAvatar(res.avatar);
            setTimeout(() => {
              this.userName = getUserName();
              this.token = getToken();
              this.avatar = getAvatar();
            }, 200);
            this.setVariableFalse();
            location.reload();
          });
        } else {
          return false;
        }
      });
    },
    // 推出登录
    loginOut() {
      removeToken();
      removeUserName();
      this.token = "";
      location.reload();
    },
    // 跳转到个人中心
    spktUser() {
      window.open(process.env.VUE_APP_TITLE_TOW + "#/personalCenter");
    },
    // 修改密码弹窗确定按钮
    lsetPwd() {
      this.$refs["ruleForm"] ? this.$refs["ruleForm"].resetFields() : null;
      this.modalObj.openPassword = true;
    },
    //点击修改密码的确认按钮、
    passwordServe() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          let params = {
            username: this.userName,
            password: this.loginObj.userPwd,
          };
          updatePwd(params).then((res) => {
            if (res.code == 200) {
              this.$message.success("操作成功");
              this.loginOut();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.myHeaderTow {
  width: 100%;
  height: 50px;
  background: #fff;
  .w1400 {
    width: 1400px;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
    background: #fff;
    .mapdata {
      float: left;
      width: 220px;
      margin-top: 9px;
      position: relative;
      padding-left: 15px;
      ::v-deep .el-input__inner {
        border: 0;
        font-weight: 600;
      }
      ::v-deep .el-input__suffix{
        width:0;
        display: none;
      }
      .mapdata-img{
        position: absolute;
        left: 5px;
        top: 2px;
        width: 25px;
        height: 25px;
        z-index: 20;
      }
    }
    .logo {
      float: left;
      width: max-content;
      height: 36px;
      margin:7px 10px 0;
      justify-content: flex-start;
      cursor: pointer;
      .logo-img2 {
        width: 252px;
        height: 40px;
      }
      .logo-img1{
        width: 167px;
        max-height: 40px;
      }
    }
    .serach-box {
      float: right;
      height: 100%;
    }
    .userCont {
      float: right;
      height: 100%;

      .flc {
        height: 100%;

        .apply {
          color: #222;
        }

        .ml20 {
          margin-left: 20px;
        }
      }
      .userName {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .tags {
    padding: 0 60px;
    text-align: left;
    .tagsItem {
      display: inline-block;
      width: 130px;
      padding: 9px 20px;
      border-right: 0;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      box-sizing: border-box;
      color: #2892ff;
      border: 1px solid rgba(40, 146, 255, 0.9);
      cursor: pointer;

      &:first-child {
        border-radius: 4px 0 0 4px;
      }

      &:last-child {
        border-radius: 0 4px 4px 0;
      }

      &:hover {
        color: #fff;
        background-color: rgba(40, 146, 255, 0.98);
      }
    }

    .active {
      background-color: rgba(40, 146, 255, 0.9);
      color: #fff;
    }
  }

  .form-modal-box {
    padding: 0 90px;
    font-size: 20px !important;

    .loginBtn {
      width: 100%;
    }

    .fz12 {
      font-size: 12px !important;
      color: red;
    }
  }
  .form-modal-login-box {
    padding: 0 60px;
  }
  .toRegisterBtn {
    padding-left: 60px;
  }
  .form-item {
    //width: 500px;
    box-shadow: 0px 4px 14px 0px rgba(142, 150, 159, 0.12),
      0px 2px 6px 0px rgba(0, 0, 0, 0.06);
  }

  .code-item {
    width: 115px;
  }
  .box-card {
    width: 100%;
    position: relative;
    .close-i {
      position: absolute;
      right: 20px;
      top: 20px;
      i {
        font-size: 20px;
        color: #999;
        cursor: pointer;
        &:hover {
          color: #1784fc;
        }
      }
    }
  }

  .login-img {
    width: 234px;
    height: 70px;
    margin: 20px auto 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
<style>
.logoModal .el-dialog .el-dialog__header {
  display: none;
}
.logoModal .el-dialog .el-dialog__body {
  padding: 0;
}
.xieyi {
  align-items: center;
}
</style>
